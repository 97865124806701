import React, { useEffect, useState } from 'react';
import Formulario from '../Contact'
import { graphql, useStaticQuery } from 'gatsby';
import LateralContact from '../lateralContact';
import { useDispatch } from 'react-redux';
import Breadcrumbs from '../breadcrumbs';
import ReCAPTCHA from 'react-google-recaptcha';
import { formTokko, updateFields } from '../../redux/contactDucks';

const settings = {
    name:'López Bisoglio & Oyarbide propiedades',
    short_name:'lbo',
    base_url:'/',
    social:
    {
        

        facebook:'https://www.facebook.com/lopezbisoglioyoyarbide/',
        instagram:'https://www.instagram.com/lopezbisoglioyoyarbide/',
        twitter:'',
        linkedin:'',
        youtube:'',
    }
    ,
    contact:
    {
        phone:['223-5357942' , '223-5228297'],
        whatsapp:{link:'542235357942',visual:'+54 223-5357942'},
        mail:'info@lopezbisoglioyoyarbide.com.ar'
    },
    location:
    {
        address:'Leandro N. Alem 3425, Piso 2, Of "A"',
        region:'Mar del Plata, Argentina',
        cp:'7600'
    },
    keys:{
        // captcha:'6Lc9VaAaAAAAANaHwR9avf7MCpVP7z019S-g_OMi', Mediahaus
        captcha:'6LdrYYEbAAAAAL7p9MykqL2BE-KjbeALCiqaUAWr',
        tokko:'7d5dc24fcb137714860c3c5924cf3ac62acb3b9a',
        google_tgm:'',
        // google_map:'AIzaSyCelKXL-cdJKcw8uWLWDGFrL6tZFASQ5uY',
        google_map:'AIzaSyDOdrBLaZgydSYoSpIRywmFxjTECvbiFcA',
        // google_map:'AIzaSyDOdrBLaZgydSYoSpIRywmFxjTECvbiFcA',
    },
    seo:{
        title:'López Bisoglio & Oyarbide propiedades',
        description:'Inmobiliaria Boutique. La sociedad surge con el deseo de emprender en la ciudad un nuevo concepto inmobiliario, en base a la experiencia vivida de ambos fundadores en el mercado.',
        url:'https://www.lopezbisoglioyoyarbide.com.ar/',
        img:'https://lopezbisoglioyoyarbide.com.ar/assets/images/logo-header-dark.svg',
        image_url:'https://lopezbisoglioyoyarbide.com.ar/assets/images/logo-header-dark.svg',
        site_name:'López Bisoglio & Oyarbide propiedades',
        locale:'es_ES',
        type:'website'
    }
}

const Main = () => {

    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              name
              global_email
              global_phone
              keys {
                captcha
              }
              social{
                facebook
                instagram
                twitter
                youtube
                linkedin
              }
              branch_office{
                name
                address
                city
                region
                broker_id
                contact {
                  phone
                  mail
                  whatsapp
                }
              }
              sections {
                    services{
                        title
                        subtitle
                        paragraph
                    }
                    footer {
                        title_contact
                        title_social
                        title_newsletter
                        title_office
                        button_contact{
                            link
                            value
                        }
                    }
              }
          }
    }`)

    const dispatch = useDispatch();
    const [step, setStep] = useState(0);
    const [disabled, setDisabled] = useState(true);
    const [datos, setDatos] = useState({
        nombre:'',
        email:'',
        text:'',
    })
    const handleInputChange = (event) => {
        setDatos({
            ...datos,
            [event.target.name] : event.target.value, 
        })
    }
    const sendForm = (event) => {
      event.preventDefault()
      setStep(2)
      window.scrollTo(0, 0)
      dispatch(formTokko(['Contacto'],null))
  }
    useEffect(() => {
        const update = () => {
            dispatch(updateFields(datos))
        }
        update()
    }, [datos])

    return(
        <section className={ "main " + (step == 2 ? "step-2 pt-0" : step == 1 ? "step-1" : "step-0")}>
            <div className="container-fluid full-fluid">
                 <Breadcrumbs props={[
                    {name:'Home',route:"/",location:''},
                    {name:'Contacto',route:'',location:'',active :true}
                ]}/>
                <div className={"row " +(step == 1? "pt-0" : step == 2? "total-vh": step == 1? "step-1"  : "")} >
                <div className={"left-column col-lg-7 " +(step == 2  ? "pt-0 d-flex justify-content-center flex-column" : "")}  >
                    {step == 2 ? (
                    <div className={ "d-flex animate__animated animate__fadeInRight animate_faster flex-column justify-content-center " +(step == 2 ? "pt-0" : "")}>
                        <div className="tick d-flex justify-content-center align-items-center">
                        <i className="icon-tick"></i>
                        </div>
                        <h2 className="text-thanks">
                        ¡Tu mensaje fue <br />
                        enviado con éxito! <br />
                        Gracias por contactarte <br />
                        con nosotros.
                        </h2>
                    </div>
                    ) : (
                    <div
                        className={
                        "d-flex flex-column justify-content-center " +
                        (step == 1 ? "d-none d-lg-flex" : "")
                        }
                    >
                        {/* <Breadcrumbs
                        props={[
                            { name: "Home", route: '/' , location: "" },
                            {
                            name: "Contacto",
                            route: "",
                            location: "",
                            active: true,
                            },
                        ]}
                        /> */}
                        <span className="title-section">CONTACTO</span>
                        <h2>
                        {settings.location.address} <br />
                        {settings.location.region} <br />
                        {settings.contact.phone[0]} // {settings.contact.phone[1]}  <br />
                        <a className="hover" href={"mailto:" + settings.contact.mail}>
                            {settings.contact.mail}
                        </a>
                        </h2>
                    </div>
                    )}
                    <a
                        onClick={() => setStep(1)}
                        className={"btn black btn-icon transparent d-inline-block " + (step == 0 ? ' ' : ' opacity ')}
                    >
                        ENVIAR MENSAJE
                    </a>
                </div>
                <div className={step == 0 ? "col-lg-5 d-none d-lg-block" : "d-none"}>
                    <div className="row justify-content-between">
                    <div className="col-2 line-vertical"></div>
                    <div className="col-2 line-vertical"></div>
                    <div className="col-2 line-vertical"></div>
                    <div className="col-2 line-vertical"></div>
                    <div className="col-2 line-vertical"></div>
                    </div>
                </div>
                {step == 1 ? ( //STEP 2
                    <div className="col-lg-5 animate__animated animate__fadeInRight animate_faster formulario d-flex flex-column justify-content-lg-start">
                    <form onSubmit={sendForm} className="form-content">
                    {/* + setStep(2) + window.scrollTo(0, 0) */}
                        <label htmlFor="">Nombre y Apellido</label>
                        <input name="nombre" type="text" onChange={handleInputChange} />
                        <label htmlFor="">E-Mail*</label>
                        <input name="email" type="text"  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" onChange={handleInputChange} required />
                        <label htmlFor="">Mensaje</label>
                        <textarea name="text" id="" cols="30" rows="1"  onChange={handleInputChange}></textarea>
                        <p className="w-100 mt-lg-2 mt-4 text-end" style={({ "font-size": 1.1 + "rem" }, { "font-weight": "400" })} >
                        *Campo obligatorio.
                        </p>
                        <div className="d-lg-flex justify-content-between w-100">
                        <div className="div w-100 order-2 order-lg-1 ">
                            <ReCAPTCHA sitekey={settings.keys.captcha} onChange={() => setDisabled(false)} />
                            <button id="btn-state" type="submit" disabled={disabled} className="btn btn-icon transparent d-inline-block mt-lg-5" > 
                            
                            ENVIAR
                            </button>
                        </div>
                        </div>
                    </form>
                    </div>
                ) : (
                    ""
                )}
                </div>
            </div>
        </section>
    )
} 

export default Main;